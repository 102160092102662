import React from 'react'
import { useRouter } from 'next/router'
import { Container } from '@chakra-ui/react'

import MainContent from '@/components/MainContent'
import AppOverview from '@/modules/Dashboard'
import { useStores } from '@/stores/index'
import { TablePageLoader } from '@/components/SkeletonLoaders'
import Meta from '@/components/Meta'

const Dashboard = () => {
  const { auth } = useStores()
  const router = useRouter()

  React.useEffect(() => {
    if (!auth.currentOrganization) {
      // admin not onboarded yet
      router.push('/onboarding')
    }
  }, [auth.currentOrganization])

  if (!auth.currentOrganization) {
    return (
      <Container pt="20">
        <TablePageLoader />
      </Container>
    )
  }

  return (
    <>
      <Meta title="Dashboard" />
      <MainContent>
        <AppOverview />
      </MainContent>
    </>
  )
}

export default Dashboard
