import { Stack, Flex, Divider, Box, useColorMode } from '@chakra-ui/react'
import { css } from '@emotion/react'

import Header from '@/components/Header'
import SideBarMenu from '@/components/SideBarMenu'
import {
  bgThemeColor,
  themeColor,
  dashboardColor,
  primaryThemeColor,
  sideBarBGThemeColor,
} from '@/lib/constants/colorConstants'
import useResponsiveFilter from '@/hooks/useResponsiveFilter'

interface MainContentProps {
  children: React.ReactElement
  filter?: React.ReactElement
  sidePanel?: React.ReactElement
  isSideBar?: boolean
}

const MainContent = ({
  children,
  filter,
  sidePanel,
  isSideBar = true,
}: MainContentProps) => {
  const { colorMode } = useColorMode()
  const { open, openFilter, responsiveFilterMode } = useResponsiveFilter()

  const filterOpenStyles = css`
    & {
      position: fixed;
      height: 100%;
      background: ${dashboardColor[colorMode]};
      right: ${openFilter ? '-15px' : '-700px'};
      top: 61px;
      padding: 20px;
      border-left: 1px solid #000;
      transition: 0.3s ease;
    }
  `

  return (
    <Stack position="relative" overflow="hidden" height="100vh">
      <Header
        bgColor={primaryThemeColor[colorMode]}
        color={themeColor[colorMode]}
        open={open}
      />
      <Flex
        flexDirection="row"
        position="relative"
        maxH="90vh"
        css={css`
          & {
            margin-top: -1px !important;
          }
        `}
      >
        {isSideBar && (
          <>
            <SideBarMenu
              bgColor={sideBarBGThemeColor[colorMode]}
              colorMode={colorMode}
              // open={open}
            />
            <Divider
              bgColor={bgThemeColor[colorMode]}
              orientation="vertical"
              height="100vh"
            />

            {sidePanel}
          </>
        )}
        <Flex
          flexGrow={1}
          bgColor={dashboardColor[colorMode]}
          color={themeColor[colorMode]}
          overflowY="scroll"
          scrollbar-width="none"
          css={css`
            &::-webkit-scrollbar {
              display: none;
            }

            & {
              scrollbar-width: none;
              -ms-overflow-style: none;
            }
          `}
        >
          <Box
            flexGrow={1}
            width="100%"
            mx="auto"
            px="40px"
            py="25px"
            height="auto"
            overflowX="scroll"
            css={css`
              &::-webkit-scrollbar {
                display: none;
              }
              & {
                scrollbar-width: none;
                -ms-overflow-style: none;
              }
            `}
          >
            {children}
          </Box>
          {filter && (
            <Box
              flexShrink={0}
              marginX="15px"
              css={
                responsiveFilterMode
                  ? filterOpenStyles
                  : css`
                      & {
                        position: sticky;
                      }
                    `
              }
            >
              {filter}
            </Box>
          )}
        </Flex>
      </Flex>
    </Stack>
  )
}

export default MainContent
