import React, { useState, useEffect } from 'react'
import { Flex, Box } from '@chakra-ui/layout'
import { css } from '@emotion/react'
import { useRouter } from 'next/router'
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  ColorMode,
  Icon,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { FaAngleDown } from 'react-icons/fa'
import { observer } from 'mobx-react-lite'
import moment from 'moment'

import { bgThemeColor, sidebarBg } from '@/lib/constants/colorConstants'
import { hasPermission } from '@/lib/helpers'
import { useSideBarMenuOptions } from '@/modules/index'
import { APP_NAME } from '@/lib/constants/appConstants'

import SideMenuTimer from '../MenuTimer'
import { CustomLink } from '../Link'
import TruncatedText from '../TruncatedText'
import { stores } from '@/stores/index'

interface SideBarMenuProps {
  bgColor?: string
  color?: string
  colorMode: ColorMode
}

const SideBarMenu = observer(({ bgColor, colorMode }: SideBarMenuProps) => {
  const route = useRouter()
  const { isOpen, onToggle } = useDisclosure()
  const { pathname, asPath } = route || { pathname: '/' }
  const SideBarMenuOptions = useSideBarMenuOptions()
  const _sideBarOptions = Object.values(SideBarMenuOptions)
  const [activeIndex, setActiveIndex] = useState<number | undefined>()
  const permissions: any[] = stores.auth.getMyPermissions()

  const navcss = (isActive: boolean) => css`
    & {
      &:hover {
        &::after {
          display: ${isActive ? 'block' : 'none'};
          background: ${isActive && sidebarBg[colorMode]};
          margin-left: 92%;
          width: 0;
          height: 0;
          border-top: 20px solid transparent;
          border-bottom: 20px solid transparent;
          border-right: 20px solid white;
        }
      }

      &::after {
        display: ${isActive ? 'block' : 'none'};
        background: ${isActive
          ? sidebarBg[colorMode]
          : bgThemeColor[colorMode]};
        margin-left: 92%;
        width: 0;
        height: 0;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-right: 20px solid white;
      }
    }
  `

  // const maincss = css`
  //   & {
  //     left: ${!open ? '-300px' : '0'};
  //     transition: 0.3s ease;
  //   }
  // `

  let currentRoute: string
  const paths = pathname.split('/')

  if (paths.length === 2) {
    currentRoute = '/'
  } else if (paths.includes('_sites')) {
    currentRoute = paths[3]
  } else currentRoute = paths[2]

  return (
    <Flex
      flexDirection="column"
      width="250px"
      py={10}
      bgColor={bgColor}
      position={{ base: 'fixed', md: 'sticky' }}
      // top="60px"
      overflow="hidden"
      flexShrink={0}
      // css={maincss}
      height="90vh"
      display={{ base: 'none', md: 'flex' }}
    >
      <SideMenuTimer />
      <Box
        overflowY="scroll"
        flexGrow={1}
        css={css`
          &::-webkit-scrollbar {
            display: none;
          }

          & {
            scrollbar-width: none;
            -ms-overflow-style: none;
          }
        `}
      >
        <Accordion
          // flex={1}
          allowMultiple
          defaultIndex={activeIndex ? [activeIndex] : undefined}
        >
          {_sideBarOptions.map(
            ({ name, icon, path, isShown, child, permission }, i) => {
              if (!isShown) return

              const currentPath = path.split('/')[1] || '/'
              const isActive = currentRoute === currentPath

              if (isActive && child?.length && activeIndex !== i)
                setActiveIndex(i)

              if (!hasPermission(permission, permissions)) return
              return (
                <NavComponent
                  key={name}
                  isActive={isActive}
                  colorMode={colorMode}
                  navcss={navcss}
                  path={path}
                  icon={icon}
                  child={child}
                  asPath={asPath}
                  name={name}
                  permissions={permissions}
                />
              )
            }
          )}
        </Accordion>
      </Box>

      <Flex flex={0.2} mt="40px" justifyContent="center" alignItems="center">
        <Text fontSize="14px" color="white">
          &copy; {moment(new Date()).format('YYYY')} {APP_NAME}
        </Text>
      </Flex>
    </Flex>
  )
})

const styles = {
  display: 'flex',
  alignItems: 'center',
  _hover: {
    textDecoration: 'none',
    color: 'inherit',
  },
  _focus: {
    border: 'none',
    textDecoration: 'none',
  },
  fontFamily: 'Poppins',
  fontWeight: 'medium',
  fontSize: '16px',
}

const NavComponent = ({
  isActive,
  colorMode,
  navcss,
  path,
  icon,
  child,
  asPath,
  name,
  permissions,
}: {
  isActive: boolean
  colorMode: ColorMode
  navcss: any
  path: string
  icon: any
  child: any
  asPath: any
  name: string
  permissions?: IUserPermission[]
}) => {
  return (
    <>
      <Box minW="100%">
        <AccordionItem border="none">
          <Box
            position="relative"
            css={navcss(isActive)}
            _after={{
              content: '""',
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
            }}
            bg={isActive ? sidebarBg[colorMode] : 'transparent'}
          >
            {child.length ? (
              <AccordionButton fontSize="16px" py={0} px={10}>
                {path ? (
                  <CustomLink
                    href={path as string}
                    {...styles}
                    width="100%"
                    textAlign="left"
                    color="white"
                    py={3}
                  >
                    <Icon
                      as={icon}
                      color="white"
                      style={{ marginRight: '20px' }}
                    />
                    {name}
                  </CustomLink>
                ) : (
                  <Box {...styles}>
                    <Icon
                      as={icon}
                      color="white"
                      style={{ marginRight: '20px' }}
                    />
                    {name}
                  </Box>
                )}

                <Icon
                  as={FaAngleDown}
                  // style={{ marginLeft: '20px', padding: '10px 10px' }}
                />
              </AccordionButton>
            ) : (
              <Box py={0} px={10}>
                <CustomLink
                  href={path as string}
                  {...styles}
                  width="100%"
                  textAlign="left"
                  color="white"
                  py={3}
                  _hover={{ color: 'gray.100' }}
                >
                  <Icon
                    as={icon}
                    color="white"
                    style={{ marginRight: '20px' }}
                  />
                  {name}
                </CustomLink>
              </Box>
            )}
          </Box>

          <AccordionPanel paddingLeft="35px">
            {child.map((childEl: any) => {
              if (!hasPermission(childEl.permission, permissions)) return
              return (
                <CustomLink
                  key={childEl.name}
                  href={childEl.path as string}
                  color={asPath === childEl.path ? 'red' : undefined}
                  {...styles}
                  _hover={{
                    textDecoration: 'none',
                    color: asPath === childEl.path ? 'red' : 'inherit',
                  }}
                  py={3}
                  px={10}
                  fontSize="14px"
                >
                  <TruncatedText>{childEl.name}</TruncatedText>
                </CustomLink>
              )
            })}
          </AccordionPanel>
        </AccordionItem>
      </Box>
    </>
  )
}

export default SideBarMenu
